import React from "react";

import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { BsWhatsapp } from "react-icons/bs";
import '../App.css'
const Carousel = () => {
  const swiperOption = {
    loop: true,
    speed: 5000,
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  };
  return (
    <div className="hero-slider-area">
      <Swiper
        navigation={true}
        modules={[Navigation]}
        {...swiperOption}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="carou-ss1">
            <img src={require("../assets/images/slider/8323069.jpg")} alt="" />
            <div className="whats-app-icon">
              <a
              target="blank"
             href="http://wa.me/+918837651715"
              >
                <img className="img-whats-app" src={require('../assets/images/whatsapp-logo.png')} alt="" />
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img
              src={require("../assets/images/slider/TRANSRO.jpg")}
              alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={require("../assets/images/slider/8323069-Recovered.jpg")}
            alt=""
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Carousel;
