
import Image1 from '../../assets/images/News/NEWS1.webp'
import Image2 from '../../assets/images/News/NEWS2.webp'


const Data = [
  {
    id: "id1",
    image: Image1,
    title: "Anup Jyoti: A Remarkable 12-Week Transformation",
    
    description: " Anup Jyoti, a 36-year-old gym owner, embarked on a 12-week transformation journey to shed weight and improve his lifestyle. Starting at 77kg, he lost an impressive 9kg, reaching 68kg. However, witnessing his potential, Anup embraced bodybuilding, preparing for an upcoming show in his off-season. With a supportive community by his side, he continues to inspire others to unlock their true potential and strive for greatness.",
    description1: "Anup Jyoti, a 36-year-old gym owner, embarked on a 12-week transformation journey to shed weight and improve his lifestyle.  ",
    // description2: "Starting at 77kg, he lost an impressive 9kg, reaching 68kg. However, witnessing his potential, Anup embraced bodybuilding, preparing for an upcoming show in his off-season. With a supportive community by his side, he continues to inspire others to unlock their true potential and strive for greatness.",
    // description4: "As i always preach LESS DRUGS & MAXIMUM EFFORT is the only way of doing bodybuilding in a longer time. Anil dedicated this prep to this ideology. ",
    // description5: "AT TEAM HEAVY DUTY we always go with the ethical bodybuilding that can be done with LONGEVITY and MAINTAINING THE INTERNAL HEALTH.",
    // description6: "Now we GEAR UP FOR THE ASIAN CHAMPIONSHIP in NEPAL in upcoming 6 weeks.",
    // description7: "ANIL CHAUDHARY'S TAKE: I literally pushed my best effort ever for this championship. The approach that coach has devised for me was great and health. Team stayed on point with me at every point. ",
    // description8: "That was a victory that just marked my start. MORE TO COME & SEE YOU IN NEPAL",
    data:'22/07/23'
  },
  {
    id: "id1",
    image: Image2,
    title: " Damanpreet Singh's 24-Week Transformation: From 87kg to 79kg",
    
    description: " Damanpreet Singh, a 28-year-old construction worker in Canada, achieved an impressive weight loss of 8kg in 24 weeks, going from 87kg to 79kg. Overcoming his busy schedule and vegetarian lifestyle, Damanpreet's dedication and resilience shine through,inspiring us all to prioritize our health and conquer any challenges that come our way",
    description1: "Damanpreet Singh, a 28-year-old construction worker in Canada, achieved an impressive weight loss of 8kg in 24 weeks..  ",
    // description2: "Starting at 77kg, he lost an impressive 9kg, reaching 68kg. However, witnessing his potential, Anup embraced bodybuilding, preparing for an upcoming show in his off-season. With a supportive community by his side, he continues to inspire others to unlock their true potential and strive for greatness.",
    // description4: "As i always preach LESS DRUGS & MAXIMUM EFFORT is the only way of doing bodybuilding in a longer time. Anil dedicated this prep to this ideology. ",
    // description5: "AT TEAM HEAVY DUTY we always go with the ethical bodybuilding that can be done with LONGEVITY and MAINTAINING THE INTERNAL HEALTH.",
    // description6: "Now we GEAR UP FOR THE ASIAN CHAMPIONSHIP in NEPAL in upcoming 6 weeks.",
    // description7: "ANIL CHAUDHARY'S TAKE: I literally pushed my best effort ever for this championship. The approach that coach has devised for me was great and health. Team stayed on point with me at every point. ",
    // description8: "That was a victory that just marked my start. MORE TO COME & SEE YOU IN NEPAL",
    data:'22/07/23'
  },
  

];

export default Data;
