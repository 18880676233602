import { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container flex-footer">
        {/* <div className="social-icons">
          <a href="#">
            <i className="fa-sharp fa-regular fa-basketball" />
          </a>
          <a href="#">
            <i className="fa-brands fa-instagram" />
          </a>
          <a href="#">
            <i className="fa-brands fa-linkedin" />
          </a>
          <a href="#">
            <i className="fa-brands fa-pinterest" />
          </a>
        </div> */}
        <div></div>
        <div className="copy-right-text">
          © 2023 - Fit Human
          {/* {new Date().getFullYear()} */}
        </div>
        <div className="flex-footer2">
          <Link to={"/Privacy"}>
            <li
              style={{
                fontSize: "15px",
                fontWeight: "300",
                cursor: " pointer",
                listStyle:'none'

              }}
            >
              {" "}
              Privacy Policy
            </li>
          </Link>

          <Link to={"/Term"}>
            <li
              style={{
                fontSize: "15px",
                fontWeight: "300",
                cursor: " pointer",
                listStyle:'none'
              }}
            >
              Term and Conditions
            </li>
          </Link>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
