import React, { useEffect, useState } from "react";
import "./Scrolltop.css";
import { FiChevronUp } from "react-icons/fi";
const Whatsappicon = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 500) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <div
        onClick={scrollToTop}
        className={'scroll-to-top2 show fadeInRight'}
      >
        <a target="blank" href="http://wa.me/+918837651715">
          <img
  className="whats-app-img-sc"
            src={require('../../assets/images/slider/whatsapp-logo.png')}
            alt=""
          />
        </a>
      </div>

 
    </div>
  );
};

export default Whatsappicon;
