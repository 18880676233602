

import { Swiper, SwiperSlide } from "swiper/react";
import { sliderProps } from "./sliderProps";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
const Testimonials = () => {
  return (
    <section id="testimonials">
   


      <div className="container" data-aos="fade-up" data-aos-duration="2000">

      <h1 className="client-h1">Clients Reviews</h1>
        <div className="testimonials-top  wow fadeInUp">
          <div className="section-title left">
   

            <h2>
            Reviews
            </h2>
          </div>
          <div className="arrows">
            <a className="arrow arrow-left active">
              <BiChevronLeft size={30} color="white" />
            </a>
            <a className="arrow arrow-right">
              <BiChevronRight size={30} />
            </a>
          </div>
        </div>
        <Swiper
          {...sliderProps.testimonials}
          className="testimonial-sliders swiper wow fadeInUp"
        >
          <SwiperSlide className="slider-item swiper-slide">

             
            <div className="slider-item-content">
              <p>
              It has been almost 6 months woth fit human now. When i started i used to be 94.4 kgs right now I am 76.1 kg. 18kg difference in six months says it all. Yes it was hard at times. Yes it felt like my weight is stuck. But the confidence the team showed in me boosted me every single day! Just talking to paaji about everything ( and i mean every little thing) would give me a huge pump and motivation to workout.
              </p>
             <p>
              
             </p>
              <div className="author-info">
             <div className="image-container">
             <img src={require('../assets/images/Review/1.jpg')} alt="image" />
                </div>
           
          
                <div className="author-info-content">
                  <h3>SRIRUP MAZUMDAR</h3>
               
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-item swiper-slide">
            <div className="slider-item-content">
              <p>
              It is really great experience paji to transform my physique under such knowledgeable coach🙌🏻
              </p>
              <p>
              Diet plan is easy to follow and workout routine is really amazing
              </p>
           
              <div className="author-info">
              <div className="image-container">
                  <img src={require('../assets/images/Review/2.jpg')} alt="image" />
                </div>
           
                <div className="author-info-content">
                  <h3>SIDDHANT AGRAWAL</h3>
                 
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-item swiper-slide">
            <div className="slider-item-content">
              <p>
              My experience with fit human:
The diet that was suggested to me had a very positive impact on my body as well as my mind.
The coaches are not only very well educated and knowledgeable about fitness, they are very compassionate and understanding and patient.
              </p>
              <div className="author-info">
              <div className="image-container">
                  <img src={require('../assets/images/Review/3.jpg')} alt="image" />
                </div>
           
                <div className="author-info-content">
                  <h3>AAKRITI SHARMA</h3>
         
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-item swiper-slide">
            <div className="slider-item-content">
              <p>
              My experience with fit human:
assistance is excellent .. decent results.. helped me conquer my stubborn fat, manpreet paaji assisted me on his off days as well❤
overall good experience 🦍
              </p>
              <div className="author-info">
              <div className="image-container">
                  <img src={require('../assets/images/Review/4.jpg')} alt="image" />
                </div>
             
                <div className="author-info-content">
                  <h3>LALIT KUMAR</h3>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-item swiper-slide">
            <div className="slider-item-content">
              <p>
              My experience with fit human:
I have been on a life changing journey with fit human channel
and big thanks to my dedicated dietician who has been a great source of support and guidance, takes genuine
interest in you and works alongside you on the weightloss journey. As they say the proof is in the pudding so I have lost 17kgs in 21 weeks and the journey continues.
              </p>
              <div className="author-info">
              <div className="image-container">
                  <img src={require('../assets/images/Review/5.jpg')} alt="image" />
                </div>
             
                <div className="author-info-content">
                  <h3>SHIVAM</h3>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-item swiper-slide">
            <div className="slider-item-content">
              <p>

              Through this process, I have gained a deep understanding of my own potential in this field. I am immensely grateful to Manpreet for pushing me beyond my limits with his unwavering motivation and inspiring words. It has been an absolute pleasure to work with Atinderjeet Singh, also known as the bodybuilding alchemist, and his incredible team at Fit Human
              </p>
              <div className="author-info">
              <div className="image-container">
                  <img src={require('../assets/images/Review/6.jpg')} alt="image" />
                </div>
             
                <div className="author-info-content">
                  <h3>Taranjeet Singh Grewal</h3>
                </div>
              </div>
            </div>
          </SwiperSlide>



          
 
        </Swiper>
      </div>
    </section>
  );
};
export default Testimonials;
