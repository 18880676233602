import React from "react";
import Homec from "../../components/Homec";
import Header from "../../components/layouts/Header";
import Footer from "../../components/layouts/Footer";

import Services from "../../components/Services";
import About from "../../components/About";
import Portfolio from "../../components/Portfolio";
import Testimonials from "../../components/Testimonials";
import Blog from "../../components/Blog";
import Contact from "../../components/Contact";
import Carousel from "../../components/Carousel";
import Newscards from '../News/Newscards';
import Data from "../../components/data/Data";
const Home = () => {
  return (
    <>
      <Header />
      <section id="Home">
      <Carousel />
      </section>
      
      <section >
      <Homec />

      </section>
      <section id='news' >
      <Newscards data={Data}/>
      </section>
      <section id="section1">
      <Blog />
  
      </section>

  
      <section id="section2">
      {/* <About /> */}
      </section>
      <section id="section3">
      {/* <Portfolio /> */}

      </section>
      <section id="section4">
      <Testimonials />

      </section>
      {/* <section id="section5">
      <Services />

      </section> */}
      <section id="section6">
      <Contact />

      </section>
    
    </>
  );
};

export default Home;
