import { useEffect, useState } from "react";
import "../../assets/scss/sections/_header.scss";
import { Link } from "react-scroll";

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`position-absolute w-100 text-white ${toggle ? "active" : ""}`}
    >
      <div
        className={`header-container ${isSticky ? "sticky fadeInDown " : ""}`}
      >
        <div className="container">
          <div className="logo">
            <Link
              activeClass="active"
              to="Home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="l1"
            >
              <img
            className="logo-nav-b"
                src={require("../../assets/images/logo.png")}
                alt=""
              />
            </Link>
          </div>
          <ul className={`menus ${toggle ? "d-block" : "menu-none"}`}>
            <li>
              <Link
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="l1"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="news"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="l1"
              >
              News
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section4"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="l1"
              >
             Reviews
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section6"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="l1"
              >
                Contact
              </Link>
            </li>
          </ul>

          <div className="toggle" onClick={() => setToggle(!toggle)}>
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
