import { useContext } from "react";

const Blog = () => {
  return (
    <section id="blog">
      <div className="container" data-aos="fade-up" data-aos-duration="2000">
        <div className="section-title">
          <h2 className="wow fadeInDown our-h1 ">Our Services</h2>
        </div>
        <div className="js-our blog-items">
          <div className="col-md-6 col-lg-4">
            <div className="blog-item wow fadeInUp">
              <div className="blog-image-container">
           
                  <img
                    src={require("../assets/images/Card/newww.webp")}
                    alt="blog image"
                  />
             
              </div>
              <div className="blog-content">
                <h4>Advanced Transformations</h4>
                <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate .
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="blog-item wow fadeInUp">
              <div className="blog-image-container">
              <img
                    src={require("../assets/images/Card/new2.webp")}
                    alt="blog image"
                  />
              </div>
              <div className="blog-content">
                <h4>
                  <a href="#blog-popup-1">Lifestyle Transformations</a>
                </h4>
                <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Blog;
