import React from "react";

import "./News.css";
import { useNavigate } from "react-router-dom";
 const Newscards = ({ data }) => {
  const navigate = useNavigate();

  return (
    <>
      {/* <div className=" head-hb">
        <div className="head-n2d-div">
          <h1 className="n2d-head">
            Why I say old chap that is spiffing do one, cup of char bite your
            arm off lavatory bugger all mate bog-standard bits and bobs I'm
            telling barmy blow.
          </h1>
        </div>
      </div> */}
      <section className="about__area ">
      
        

              {/* <span>Our Team</span> */}
              <h2  className="news-h1d">News</h2>
        
   

          <div className="news-d2">
            {data.map((item) => (
              <>
                <div className="new-d1">
                  <img className="card-img-news" src={item.image} alt="" />
                  <div className="new-d3">
                    <h1 className="card-news-h1">{item.title}</h1>
                    <p className="card-news-p1">
                      {item.description1}
                      <span
                        className="read-more-span"

                        onClick={() =>
                          navigate("/NewsDetails", { state: { news: item } })
                        }

                
                      >
                        Read More.
                      </span>
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
 
      </section>
    </>
  );
};
export default Newscards;