import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  ScrollRestoration,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import "./assets/css/animate.css";
import "./assets/css/magnific-popup.min.css";
import "./assets/css/animate.css";
import "./assets/css/main.css";
import "./assets/css/main.css.map";
import "./assets/css/swiper-bundle.min.css";
import "./assets/scss/main.scss";
import "./assets/scss/components/_animation.scss";
import "./assets/scss/components/_bs.scss";
import "./assets/scss/components/_buttons.scss";
import "./assets/scss/components/_cursor.scss";
import "./assets/scss/components/_dark.scss";
import "./assets/scss/components/_modal.scss";
import "./assets/scss/components/_pogress.scss";
import "./assets/scss/components/_preloader.scss";
import "./assets/scss/components/_section-title.scss";
import "./assets/scss/components/_theme.scss";
import "./assets/scss/sections/_about.scss";
import "./assets/scss/sections/_blog.scss";
import "./assets/scss/sections/_client.scss";
import "./assets/scss/sections/_contact.scss";
import "./assets/scss/sections/_footer.scss";
import "./assets/scss/sections/_header.scss";
import "./assets/scss/sections/_hero.scss";
import "./assets/scss/sections/_portfolio.scss";
import "./assets/scss/sections/_service.scss";
import "./assets/scss/sections/_testimonials.scss";
import ScrollTop from "./components/layouts/ScrollTop";
import ScrollToTop from "./components/ScrollToTop";
import { Toaster } from "react-hot-toast";
import Privacy from "./pages/Policy/Privacy";
import Term from "./pages/Policy/Term";
import Footer from "./components/layouts/Footer";
import NewsDetails from "./pages/News/NewsDetails";
import Whatsappicon from "./components/layouts/Whatsappicon";
const App = () => {
  return (
    <Router>
      <Toaster />

      <ScrollTop />
      <ScrollToTop />
      <Whatsappicon/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Privacy" element={<Privacy />} />
        <Route exact path="/Term" element={<Term />} />
        <Route exact path="/NewsDetails" element={<NewsDetails />} />

      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
