import Counter from "./Counter";
import { AiFillApple } from 'react-icons/ai';
import { BsGooglePlay } from 'react-icons/bs';

const Homec = () => {
  return (
    <section id="home" className="text-white bg-hero-bg">
      <div className="">
        <div className="d-f-home1">
 

          <div className=" lef-h-content-1">
            <h1 className="wow fadeInDown" data-wow-delay=".5s">
              Get The App
            </h1>
            <p className="short-bio wow fadeInDown" data-wow-delay=".6s">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor
              quod sint voluptatibus amet, modi aperiam necessitatibus,
              exercitationem at corporis numquam natus sed dolores nulla non qui
              fugiat repellendus accusantium nobis.
            </p>
            {/* <a
              href="#contact"
              className="cf-btn wow fadeInDown"
              data-wow-delay=".7s"
            >
              Conect with me
              
            </a> */}
            <div className="btns-home">
                <a target="blank" href="https://apps.apple.com/in/app/fit-human/id6448666274">
              <div className="btn-apple">

                <AiFillApple size={40}/>
                <div className="lines-btn">
                  <span className="spa-f">Dowload on the</span>
                  <span className="spa-f">App Store</span>
                </div>
              </div>
                </a>
                <a target="blank" href="https://play.google.com/store/apps/details?id=com.cofox.kahunas.FITHUMAN&hl=en&gl=US">

              <div className="btn-android ">
                <BsGooglePlay size={30}/>
                <div className="lines-btn">
                  <span className="spa-f">Get it on</span>
                  <span className="spa-f">Google play</span>
                </div>
              </div>
                </a>
            </div>
            <div
              className="counter d-flex align-center content-space-between wow fadeInDown f-wrap1"
              data-wow-delay="1.7s"
            >
              <div className="experence">
                <h2>
                  <Counter end={7} />+
                  
              
                </h2>
                <p className="font-20px">Year Exprence</p>
              </div>
              <div className="project">
                <h2>
                  <Counter end={300} />+
                </h2>
                <p className="font-20px">Complete Project</p>
              </div>
              <div className="clients">
                <h2>
                  <Counter end={135} />+
                </h2>
                <p className="font-20px">Heppy Client</p>
              </div>
            </div>
          </div>
          <div className="rotate-dd">
          <img
          className="img-screenshot scale-img1"
                src={require("../assets/images/w4.png")}
                alt="image"
              />
                   <img
          className="img-screenshot"
                src={require("../assets/images/w2.jpg")}
                alt="image"
              />
      <img
          className="img-screenshot scale-img2"
                src={require("../assets/images/w3.jpg")}
                alt="image"
              />
          </div>
    

        </div>
      </div>

    </section>
  );
};
export default Homec;
