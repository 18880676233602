import React from 'react'

import "./News.css";

import { useLocation } from "react-router-dom";

import Header2 from '../../components/layouts/Header2';
const NewsDetails = () => {
    const location = useLocation();

    const news = location.state.news;
  return (
    <>


<Header2 />

      <div className="newz-card-det1">
        <div className="new-details-card">
          <img className="new-d-img" src={news.image} alt="" />
          <h1 className="new-d-h1">{news.title}</h1>
          <h1 className="new-d-h2">Date : {news.data}</h1>

          <div>
            <p className="news-d-p">{news.description}</p>
            <p className="news-d-p">{news.description2}</p>
            <p className="news-d-p">{news.description3}</p>
            <p className="news-d-p">{news.description4}</p>
            <p className="news-d-p">{news.description5}</p>
            <p className="news-d-p">{news.description6}</p>
            <p className="news-d-p">{news.description7}</p>
            <p className="news-d-p">{news.description8}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewsDetails